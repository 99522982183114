import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useLayoutEffect,
} from "react";

// import "../clientes/clientes.css";
import "./propiedades.css";
import {
  Download,
  XCircleFill,
  PencilFill,
  EyeFill,
  TrashFill,
} from "react-bootstrap-icons";
//jQuery libraries

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import {
  Typography,
  Button
} from "@mui/material";
import {
  properties,
  publishPropertie,
  noPublishPropertie,
} from "../../services";

import BreadCrumb from "../../components/navbar/Breadcrumb";
import Swal from "sweetalert2";
import { usePropiedades } from "../../hooks";
import { DataTable, FiltrosPropiedades, DrawerSucursal } from "../../components";
import { UserContext } from '../../hooks/UserContext'; // Importa el contexto
import { useUser } from "../../hooks/UserContextLogin";
import { checkSectionViewPermision } from "../../utilities/permissionHelper";

const Propiedades = () => {
  //const [propiedades, dispatch] = useReducer(reducer, prs);
  const { loading, data, fetchData, limite } = usePropiedades();
  // console.log(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtros, setFiltros] = useState({});
  const [sideOpen, setSideOpen] = useState(false);
  const [sucursal, setSucursal] = useState({});
  const [propiedad, setPropiedad] = useState({});
  const { usuario } = useContext(UserContext); // Accede al contexto del usuario
  const { user, setUser } = useUser();


  const headers = [
    {
      name: "referencia",
      numeric: false,
      disablePadding: false,
      label: "Referencia",
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: "#33383b !important",
            "&:hover": { color: "#33383b" },
            "&:active": { color: "#33383b" },
            fontSize: "0.9rem",
            fontWeight: "1rem"
          }}
        >
          {row.referencia}
        </Typography>
      ),
    },
    {
      name: "agente",
      numeric: false,
      disablePadding: false,
      label: "Agente",
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: "#33383b !important",
            "&:hover": { color: "#33383b" },
            "&:active": { color: "#33383b" },
            fontSize: "0.9rem",
            fontWeight: "1rem"
          }}
        >
          {row.agente}
        </Typography>
      ),
    },
    {
      name: "sucursal",
      numeric: false,
      disablePadding: false,
      label: "Sucursal",
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: "#33383b !important",
            "&:hover": { color: "#33383b" },
            "&:active": { color: "#33383b" },
            fontSize: "0.9rem",
            fontWeight: "1rem"
          }}
        >
          {row.sucursal_municipio}
        </Typography>
      ),
    },
    {
      name: "provincia",
      numeric: false,
      disablePadding: false,
      label: "Provincia",
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: "#33383b !important",
            "&:hover": { color: "#33383b" },
            "&:active": { color: "#33383b" },
            fontSize: "0.9rem",
            fontWeight: "1rem"
          }}
        >
          {row.provincia}
        </Typography>
      ),
    },
    {
      name: "ciudad",
      numeric: false,
      disablePadding: false,
      label: "Ciudad",
      overrideFunc: (data, row) => (
        <Typography>
          {row.ciudad}
        </Typography>
      ),
    },
    {
      name: "conservacion",
      numeric: false,
      disablePadding: false,
      label: "Estado",
      overrideFunc: (data, row) => (
        <Typography>
          {row.conservacion}
        </Typography>
      ),
    },
    {
      name: "from_mls",
      numeric: false,
      disablePadding: false,
      label: "Colaborador",
      overrideFunc: (data, row) => (
        <Typography>
          {row.from_mls}
        </Typography>
      ),
    },
    {
      name: "published",
      numeric: false,
      disablePadding: false,
      label: "Situación",
      overrideFunc: (data, row) => (
        row.published ?
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "1rem"
            }}
            className="badge rounded-pill badge-publiched"
            // {checkSectionViewPermision(user,"edit","Propiedades") && disable}

            // disabled={
            //   !checkSectionViewPermision(user, "edit", "Propiedades") ||
            //   !user?.rol?.permisos?.tablas['Propiedades']?.includes('Editar') ||
            //   row.sucursal_id !== user.sucursal.id
            // }
            onClick={(e) => {
              // noPublicarPropertie(row.id);
              noPublicarPropertie(row.id);
            }}
          >
            Publicado
          </Button>
          :
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "1rem"
            }}
            // disabled={
            //   !checkSectionViewPermision(user, "edit", "Propiedades") ||
            //   !user?.rol?.permisos?.tablas['Propiedades']?.includes('Editar') ||
            //   row.sucursal_id !== user.sucursal.id
            // }
            className="badge bg-danger rounded-pill text-light"
            onClick={(e) => {
              publicarPropertie(row);
              publicarPropertie(row);
            }}
          >
            No publicado
          </Button>

      ),
    },
  ];

  if (checkSectionViewPermision(user, "edit", "Propiedades")) {
    headers.push({
      actions: "actions",
      numeric: false,
      disablePadding: false,
      label: "Acciones",
      overrideFunc: (data, row) => (
        user?.rol?.permisos?.tablas['Propiedades']?.includes('Editar')
          ? (row.numsucursal == user.sucursal.id_ext)
            ? <Link to={`/propiedades/${row.referencia}`}>
              <button className="btn btn-outline-dark" style={{ marginLeft: "10px" }}>
                <PencilFill />
              </button>
            </Link>
            : <Typography>
              Sin permisos
            </Typography>
          : <Link to={`/propiedades/${row.referencia}`}><button className="btn btn-outline-dark" style={{ marginLeft: "10px" }}>
            <PencilFill />
          </button></Link>
      ),

    });
  }

  useEffect(() => {
    // console.log(user);
    // console.log(user?.rol?.permisos?.tablas['Propiedades']);
    if (user?.rol?.permisos?.tablas?.hasOwnProperty("Propiedades")) {
      // const sucursalesValues = userPermision?.rol?.permisos?.tablas[originalTable];
      // console.log(user?.rol?.permisos?.tablas['Propiedades']);
      // headers = headers.filter(item => !userPermision.rol.permisos.tablas[originalTable].includes(item.label));
    }
  }, [])
  // console.log(data);

  const publicarPropertie = async (prop) => {
    setPropiedad(prop);
    if (!prop.numsucursal) {
      setSideOpen(true); // Abre el drawer solo si numsucursal es null o undefined
    } else {
      const id_propiedad = prop?.id;

      const res = await publishPropertie({ id_propiedad });
      // console.log(res)
      if (res == 201) {
        Swal.fire({
          icon: "success",
          title: `Publicacion con referencia ${propiedad?.referencia} hecha correctamente!`,
          showConfirmButton: false,
          timer: 1500,
        });

        await fetchData({ offset: currentPage, limit: limite, filtros: filtros });
      } else {
        Swal.fire({
          icon: "error",
          title: `Error!`,
          text: `${res === 404 ? "No se encuentra la publicación" : ""}`,
          timer: 1500,
        });
      }
    }
  };
  const publicarPropertieAddSucursal = async (datosSucursal) => {

    const { id } = propiedad;
    const id_propiedad = id;
    const { id_ext } = datosSucursal;

    // console.log(id_ext)
    const res = await publishPropertie({ id_propiedad, "numsucursal": id_ext });
    setSideOpen(!sideOpen);

    await fetchData({ offset: currentPage, limit: limite, filtros: filtros });
    if (res == 201) {
      Swal.fire({
        icon: "success",
        title: `Publicacion con referencia ${propiedad?.referencia} hecha correctamente!`,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: `Error!`,
        text: `${res === 404 ? "No se encuentra la publicación" : ""}`,
        timer: 1500,
      });
    }
  };
  const noPublicarPropertie = async (id_propiedad) => {
    Swal.fire({
      title: "¿Está seguro de que quiere eliminar la publicación?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: `Si`,
      confirmButtonColor: "#48cc90",
      denyButtonText: `No`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // console.log(currentPage, limite);

        const res = await noPublishPropertie(id_propiedad);
        await fetchData({ offset: currentPage, limit: limite, filtros: filtros });

        if (res === 201) {
          Swal.fire(
            "¡Eliminado!",
            "Publicacion Eliminada correctamente",
            "success"
          );

        }
      }
    });
  };
  const handleSubmit = async () => {
    //console.log(filtros)
    await fetchData({ offset: currentPage, limit: limite, filtros: filtros });
  };

  return (
    <>
      <div className="container">
        {/* <BreadCrumb /> */}
        <div className="topline">
          <div>
            <h3>Propiedades</h3>
          </div>
          <div>
            {/* <button className='btn btn-default me-2' onClick={() => reportProperties(filtrosSeleccionados)}><Download /></button> */}
            {/* <Link to={'/propiedades/crear'}><button className='btn btn-dark'><small className='d-flex'>Crear Inmueble</small></button></Link> */}
          </div>
        </div>
        <div className="middleline">
          <FiltrosPropiedades
            filtros={filtros}
            setFiltros={setFiltros}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className="middleline">

          <DataTable
            headers={headers}
            data={data}
            isLoading={loading}
            fetcher={fetchData}
            mensaje={"No hay propiedades disponibles"}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filtros={filtros}
            properties={properties}
          />
        </div>
        <DrawerSucursal
          open={sideOpen}
          setOpen={setSideOpen}
          setSucursal={setSucursal}
          publicarPropertie={publicarPropertieAddSucursal} />
      </div>
    </>
  );
};

export default Propiedades;
